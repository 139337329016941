<template>
  <b-container class="bv-row">
    <b-row>
      <b-col offset="2" cols="8">
        <div>
          <!-- porco dio -->

          <b-embed
            type="iframe"
            aspect="16by9"
            src="https://uniframe-dev.id/uni=1"
          ></b-embed>
        </div>
    </b-col>
  </b-row>
</b-container>
</template>

<script>



  export default {
    name: 'public-iframe',
    components: {

    },

    data() {
      return {
      }
    },

    methods: {
    },
    mounted: function onMount() {
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- porco dio -->

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 5% 10px;
}

a {
  color: #42b983;
}

.bv-row {
  background: green;
}
</style>
