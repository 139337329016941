import VueRouter from 'vue-router'
import publicControl from '../components/Publiccontrolstep1.vue'
import iframe from '../components/iframe.vue'


let routes;
// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
if ( process.env.NODE_ENV == 'development') {
   routes = [
    { name: 'Home', path: '/uni=:id', component: publicControl },
   //only for test
     { path: '/iframe', component: iframe }

   //only for test
  ]
} else {
  routes = [
    { name: 'Home', path: '/uni=:id', component: publicControl },
  ]
}
// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
//  base: process.env.BASE_URL,
  routes // short for `routes: routes`
})

export default router
